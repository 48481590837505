export const m = {
  bqhd: "MAX",
  hdxq: "项目介绍",
  zhuye: "主页",
  sqtjcg: "授权成功",
  sqtjsb: "授权失败",
  yebz: "余额不足",
  nomore: "没有更多了",
  tixian: "申请提现",
  txjl:"提现记录",
  tbgnzt: "提币功能暂停",
  zwkf: "温馨提示 : 未开放!",
  ktslbz: "可提币数量不足",
  asdds: "输入或长按粘贴地址",
  tbsl: "输入提現数量",
  sxf: "手续费",
  qdylq: "确认要领取嗎？",
  lqlj: "确定领取",
  tjiaolog: "提取中...",
  tibsuc: "提幣成功",
  tiberr: "提幣失敗",
  fzdz: "复制地址",
  noader: "您绑定的邀請地址不符合绑定要求！",
  loading: "加載中...",
  zzqb: "支持錢包",
  qbsqtt: "钱包授权您的账户",
  sqinfo: "要使用您个人帐户的功能，请使用钱包授权",
  sqbtn: "钱包授权",
  sqload: "授權中...",
  dltt: "登陆到您的账户",
  dlinfo: "要使用您个人帐户的功能，请点击连接钱包",
  dlbtn: "连接钱包",
  xzlang: "选择语言",
  whqyqdz: "未获取到邀请人地址信息",
  bangsuc: "绑定成功",
  banger: "绑定失敗",
  qxsq: "请您先授权！",
  bdyqrdz: "请您绑定邀请人地址！",
  qsydzdl: "請使用HECO地址登錄遊戲",
  yqlink: "邀请连接",
  yqinfo: "通过邀请连接分享给你的伙伴",
  yqr: "推荐人",
  yqjl: "邀请记录",
  fzcg: "复制成功",
  fzsb: "复制失败",
  reasuc: "刷新成功",
  bdtt: "绑定上级",
  bdinfo: "请输入推荐人钱包地址",
  bdbtn: "确认绑定",
  bdload: "绑定中...",
  yqtg: "我的社区",
  djs: "运行倒计时",
  zfload: "支付中...",
  qdzf: "确定支付",
  wan: "萬",
  cyje: "支付数量",
  jrsy: "今日收益",
  ljsy: "累计收益",
  rgjl: "我的订单",
  dj: "等级",
  zfje: "支付金额",
  zzc: "资产余额",
  day: "天",
  shi:"时",
  fen:"分",
  miao:"秒",
  zfsl: "支付数量",
  fxrs: "分享人数",
  sqrs: "社区人数",
  grrgzc: "个人认购数量",
  ztrgzc: "直推认购数量",
  jtrgzc:"间推认购数量",
  sqrgzc: "社区认购数量",
  fxjl: "分享奖励",
  sqjl: "社区奖励",
  zxtbed: "最小提币额度",
  syz: "收益中",
  wdfxrs: "我的分享人数",
  wdsqrs: "我的社区人数",
  sbzl: "万能NFT",
  fhsy: "分紅收益",
  sqsy: "社区收益",
  qysy: "權益收益",
  lqkc: "领取扣除",
  pttj: "平台添加",
  ptkc: "平台扣除",
  xzrged: "选择认购数量",
  qrcy: "确定认购",
  zlsl: "万能NFT",
  zfje: "支付金额",
  zldj: "认购价格",
  zljs: "认购结算",
  zfqr: "认购确认",
  azq: "安裝期",
  yyz: "預約中",
  yxz: "運行中",
  yjs: "已結束",
  wddd: "我的订单",
  rgcg: "认购成功",
  rgsb: "认购失敗",
  lqsg: "领取成功",
  lqsb: "领取失敗",
  yhdj: "用户等级",
  wdtg: "我的分享",
  grlqze: "个人领取总额",
  sqlqze: "社区领取总额",
  yeqbmx: "资产明细",
  kssj: "开始时间",
  jzsj: "截止时间",
  bdsj: "绑定时间",
  yhdz: "用户地址",
  qbye:"钱包余额",
  qxrg:"取消支付",
  azdjs:"安装倒数计时",
  yxdjs:"运行倒数计时",
  qrz:"确认中",
  wdnft:"NFT认购记录",
  rgsl:"认购数量",
  qyjs:"产品介绍",
  qy1:'1.全网限量发售',
  qy2:"2.一经售出，概不退款。",
  qy3:'权益①享受平台参投全部。',
  qy4:'权益②上线即可获得算力，享受收益。',
  nyrg:"您已认购过该NFT",
  dytr:'电影投入',
  jyhd:'解约获得',
  jtjl:'静态奖励',
  tjjl:'推荐奖励',
  sqjl:'社区奖励',
  fhqb:"Aleo钱包",
  jlqb:"MAX钱包",
  fgqb:"复购钱包",
  lqz:"提现中...",
  wdtz:'认购记录',
  xmjd:"销售进度",
  lx:"类型",
  ed:"额度",
  zt:"状态",
  jxz:"进行中",
  yjs:'已结束',
  ystz:"算力租赁",
  ysxq:"产品购买",
  grtred:"个人认购额",
  grztzc:"个人算力值",
  zttred:"直推认购额",
  ztztzc:"直推算力值",
  jtztzc:"间推算力值",
  sqtred:"社区认购额",
  sqztzc:"社区算力值",
  yped:"影片额度",
  tzed:"投资额度",
  yplj:"影片链接",
  xqjs:"详情介绍",
  wytz:"我要投资",
  tzqj:"投资区间",
  dappqb:"Dapp钱包",
  tzje:"投资金额",
  fgqbye:"复购钱包余额",
  zfje:"支付金额",
  zfqb:"支付钱包",
  cjjd:"出局节点",
  zfsb:"支付失败",
  sqjy:"申请解约",
  rgq:"封装期",
  yjy:"已解约",
  shcg:'解约成功',
  shsb:"解约失败",
  zxtzed:"最小投资额度",
  dqbj:"当前价格",
  fhtx:"提现扣除",
  jltx:"提现扣除",
  jlfg:"奖励复购积分",
  rgjl:'认购奖励',
  zfxl:'总发行量',
  zyc:'Tfilm资源池',
  yxhl:'已销毁量',
  wljjc:'未来基金池',
  shz:"审核中",
  txcg:"提现成功",
  zwsj:"暂无数据",
  typen1:'USDT静态产出MAX',
  typen2:"USDT动态产出MAX",
  typen3:'算力静态产出ALEO',
  typen4:"算力动态产出ALEO",
  ljgm:"立即购买",
  jiage:"价格",
  slzl:"算力租赁",
  danjia:'单价',
  zongsu:"总数",
  goumaifs:"购买份数",
  goumaize:"购买总额",
  zengsosl:"赠送算力",
  yunxinzouqi:"运行周期",
  yiyunxints:"已运行天数",
  fen:"份",
  suanli:"算力",
  zhouqi:"周期",
  gmsl:"购买数量",
  zfze:"支付总额",
  ndye:"您的余额不够支付",
  zxed:"最小认购数量1份",
  qyjf:"权益积分",
  xhsl:"销毁数量",
  wxtszwkf:"温馨提示：您的提现功能暂未开放！",
  qxztzed:"当前项目已结束！",
  shengyu:"剩余",
  txdz:"请输入提现地址"









};
